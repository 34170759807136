<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/finance-growth.png" class="h-20 inline-block" />
        پرداخت ها
      </h2>
    </template>
    <template #default>
      <div
        class="
          p-5
          mb-3
          border border-blueGray-200
          bg-blueGray-100
          rounded-md
          flex flex-wrap
          gap-y-3
        "
        v-for="pay in pays"
        :key="pay.id"
      >
        <div class="w-full sm:w-5/12">
          <router-link
            v-if="pay.user"
            :to="
              pay.user_panel ? { name: 'Panel', params: { id: pay.user_panel?.id } } : '/'
            "
            class="text-sky-500"
          >
            {{ pay.user.first_name }} {{ pay.user.last_name }}
          </router-link>
          <div v-else>-</div>
        </div>
        <div class="w-4/12 sm:w-3/12">شناسه: {{ pay.id }}</div>
        <div class="w-4/12 sm:w-2/12">{{ pay.pay_for }}</div>
        <div class="w-4/12 sm:w-2/12">{{ $number.format(pay.amount) }} تومان</div>
        <div class="w-full sm:w-6/12">
          {{ pay.created_at.fa }} ساعت {{ pay.created_at.time }} ({{
            pay.created_at.diff
          }})
        </div>
        <div class="w-full sm:w-6/12 text-right" dir="ltr">{{ pay.created_at.def }}</div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import _ from "lodash";

export default {
  components: { BodySimple },
  data() {
    return {
      pays: [],
      // byType: [],
      firstLoading: true,
      L_: _,

      // barPercentage: 0.1,

      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            usePointStyle: true,
            // pointStyle: "circle",
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            // stacked: true,
          },
          y: {
            // stacked: true,
            beginAtZero: true,
          },
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.res = {};
      $this.$axios
        .get("/api/admin/payments")
        .then(function (response) {
          console.log(response);
          $this.pays = response.data.pays;
          // $this.byType = response.data.incomes;
          // $this.info = response.data.info;
          // console.log(_.find($this.info, ["id", 18])?.result?.pending_update_count);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
